/*
// .about
*/
@import '../variables';
@import '../mixins/breakpoints';


@if($header-layout == spaceship) {
    @include media-breakpoint-up(xl) {
        .about__body {
            grid-template-rows: (100px + $spaceship-ledge-height) auto auto;
        }
    }
}
